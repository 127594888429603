// Copyright © 2024 CATTLEytics Inc.

import { mdiProgressCheck } from '@mdi/js';
import Icon from '@mdi/react';
import { addDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useState } from 'react';
import { Badge, ButtonGroup, Dropdown, Stack } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import DateTime from '../../common/components/DateTime';
import { TaskStatusIcon } from '../../common/components/TaskStatusIcon';
import { Sort } from '../../common/enums';
import { useSettingsContext } from '../../common/store/useSettingsContext';
import {
  animalLinkReplacer,
  api,
  IconCommentComplete,
  IconComplete,
  IconCopyToClipboard,
  IconDelete,
  IconEdit,
  IconNotComplete,
  IconPriorityHighest,
  IconRepeat,
  IconView,
  replaceMentionValuesWithNode,
} from '../../common/utilities';
import {
  ApiResourceV1,
  HttpMethod,
  QueryKey,
  Task,
  TaskCategory,
  TaskPriority,
  TaskStatus,
  User,
} from '../../shared';
import { TaskMode } from '../../shared/enums/taskMode';
import { TaskCategoryBadge } from './TaskCategoryBadge';
import TaskCommentModal from './TaskCommentModal';
import { TaskModal } from './TaskModal';
import { useSearchParams } from './useSearchParams';
import { appendTaskGroup } from './utilities';

export interface TaskTableFilters {
  assignedTo?: User[];
  categories?: TaskCategory[];
  completed?: 'show' | 'only' | 'hide';
  dateRange?: { endDate?: Date | null; startDate?: Date | null };
  includeUpcoming?: boolean;
  search?: string;
}

export interface TaskTableProps {
  filters?: TaskTableFilters;
  initialLimit?: number;
  initialOffset?: number;
}

function queryParams(options: {
  filters?: TaskTableFilters;
  includeUpcoming?: boolean;
  limit: number;
  offset: number;
  onlyCompleted?: boolean;
  showCompleted?: boolean;
  sortDirection: Sort;
  sortField: string;
  timeZone: string;
}): Record<string, string> {
  const items: Record<string, string> = {
    limit: String(options.limit),
    offset: String(options.offset),
    sortField: options.sortField,
    sortDirection: options.sortDirection,
  };

  if (options.onlyCompleted) {
    items.onlyCompleted = '1';
  }

  items.showCompleted = options.showCompleted ? '1' : '0';
  items.includeUpcoming = String(options.includeUpcoming ?? false);

  if (options.filters) {
    if (options.filters.assignedTo && options.filters.assignedTo.length > 0) {
      items.assignedUserIds = options.filters.assignedTo.map((u) => String(u.id)).join(',');
    }
    if (options.filters.categories && options.filters.categories.length > 0) {
      items.categoryIds = options.filters.categories.map((c) => String(c.id)).join(',');
    }
    if (options.filters.search) {
      // search the description
      items.search = options.filters.search;
    }

    if (options.filters.dateRange) {
      if (options.filters.dateRange.startDate) {
        items.dueDateStart = zonedTimeToUtc(
          options.filters.dateRange.startDate,
          options.timeZone,
        ).toISOString();
      }
      if (options.filters.dateRange.endDate) {
        items.dueDateEnd = zonedTimeToUtc(
          addDays(options.filters.dateRange.endDate, 1),
          options.timeZone,
        ).toISOString();
      }
    }
  }

  return items;
}

export function TaskTable(props: TaskTableProps): JSX.Element {
  // fill in with a table of tasks instead of a list.
  const settings = useSettingsContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<string>('dueDate');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);
  const history = useHistory();
  const [searchParam, setSearchParam] = useSearchParams({
    offset: String(props.initialOffset ?? 0),
    limit: String(props.initialLimit ?? 100),
  });

  const tasksQuery = useQuery<Task[]>(
    [
      QueryKey.Tasks,
      searchParam.get('limit'),
      searchParam.get('offset'),
      sortField,
      sortDirection,
      props.filters?.completed,
      props.filters,
    ],
    () =>
      api<Task[]>(HttpMethod.Get, ApiResourceV1.Tasks, {
        params: queryParams({
          limit: parseInt(searchParam.get('limit') ?? '10'),
          offset: parseInt(searchParam.get('offset') ?? '0'),
          sortField,
          sortDirection,
          showCompleted: props.filters?.completed === 'show',
          onlyCompleted: props.filters?.completed === 'only',
          includeUpcoming: props.filters?.includeUpcoming ?? false,
          filters: props.filters,
          timeZone: settings.timeZone,
        }),
      }),
    {
      keepPreviousData: true,
    },
  );

  const [taskModalVisible, setTaskModalVisible] = useState<boolean>(false);
  const [taskDeleteModalVisible, setTaskDeleteModalVisible] = useState<boolean>(false);
  const [taskToDelete, setTaskToDelete] = useState<Task>();
  const [taskModalTask, setTaskModalTask] = useState<Task>();
  const [taskCompleteWithCommentModalVisible, setTaskCompleteWithCommentModalVisible] =
    useState<boolean>(false);

  const deleteMutation = useMutation(
    () => {
      if (taskToDelete) {
        return api(HttpMethod.Delete, `${ApiResourceV1.Tasks}/${taskToDelete.id}`);
      } else {
        return new Promise<null>((resolve) => resolve(null));
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.Tasks);
      },
    },
  );

  const mutationCompleteTask = useMutation<Task | undefined, undefined, number>(
    (taskId) => {
      return api(HttpMethod.Patch, `${ApiResourceV1.Tasks}/${taskId}`, {
        body: { id: String(taskId), completed: String(true), status: TaskStatus.Completed },
      });
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries(QueryKey.Tasks),
    },
  );

  const mutationPendingTask = useMutation<Task | undefined, undefined, number>(
    (taskId) => {
      return api(HttpMethod.Patch, `${ApiResourceV1.Tasks}/${taskId}`, {
        body: { id: String(taskId), completed: String(false), status: TaskStatus.Pending },
      });
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries(QueryKey.Tasks),
    },
  );

  const mutationInProgressTask = useMutation<Task | undefined, undefined, number>(
    (taskId) => {
      return api(HttpMethod.Patch, `${ApiResourceV1.Tasks}/${taskId}`, {
        body: { id: String(taskId), completed: String(false), status: TaskStatus.InProgress },
      });
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries(QueryKey.Tasks),
    },
  );

  const headers: DataTableHeader[] = [
    { name: 'status', label: 'Status', sortable: false, colClassName: 'align-content-baseline' },
    {
      name: 'taskGroupName',
      label: 'Class',
      sortable: false,
      className: 'd-none d-lg-table-cell',
      colClassName: 'align-content-baseline d-none d-lg-table-cell',
    },
    {
      name: 'description',
      label: 'Title',
      className: 'flex-grow-1',
      colClassName: 'align-content-baseline',
    },
    {
      name: 'taskCategoryId',
      label: 'Category',
      colClassName: 'align-content-baseline',
    },
    // {
    //   name: 'startDate',
    //   label: 'Start Date',
    //   colClassName: 'align-content-baseline align-baseline',
    // },
    {
      name: 'dueDate',
      label: 'Due Date',
      className: 'd-none d-lg-table-cell',
      colClassName: 'align-content-baseline align-baseline d-none d-lg-table-cell',
    },
    {
      name: 'assignedUser',
      label: 'Assigned',
      colClassName: 'align-content-baseline align-baseline',
    },
    {
      name: 'completed',
      label: 'Completed',
      colClassName: 'align-content-baseline align-baseline',
    },
    {
      name: 'actions',
      label: 'Actions',
      colClassName: 'ms-auto',
      sortable: false,
      clickable: false,
      includesButtons: true,
    },
  ] as DataTableHeader[];

  const showModal = (task: Task): void => {
    setTaskModalVisible(true);
    setTaskModalTask(task);
  };

  const showCompleteWithCommentModal = (task: Task): void => {
    setTaskCompleteWithCommentModalVisible(true);
    setTaskModalTask(task);
  };

  const showDeleteModal = (task: Task): void => {
    setTaskDeleteModalVisible(true);
    setTaskToDelete(task);
  };

  const validData =
    tasksQuery.data && tasksQuery.data.length > 0
      ? tasksQuery.data
          .map((task) => appendTaskGroup(task, settings.timeZone))
          .sort((a, b) => {
            // other ordering?
            return a.taskGroupOrder - b.taskGroupOrder;
          })
          .map((task) => {
            return {
              id: String(task.id),
              taskGroupName: (
                <>
                  {task.scheduleMode === TaskMode.Repeat && <>Repeat / {task.taskGroupName}</>}
                  {task.scheduleMode !== TaskMode.Repeat && <>{task.taskGroupName}</>}
                </>
              ),
              taskGroupOrder: String(task.taskGroupOrder),
              status: <TaskStatusIcon overdue={task.isOverdue} size="32px" status={task.status} />,

              description: (
                <>{replaceMentionValuesWithNode(task.description, animalLinkReplacer)}</>
              ),
              notes: <>{replaceMentionValuesWithNode(task.notes ?? '', animalLinkReplacer)}</>,
              taskCategoryId: (
                <>
                  {task.taskCategory && <TaskCategoryBadge category={task.taskCategory} />}
                  {task.animalId && (
                    <Badge bg={'primary'} className={'me-3'} pill>
                      {t('taskTable|animalBadge')}
                    </Badge>
                  )}
                  {task.priority === TaskPriority.Highest && (
                    <IconPriorityHighest
                      className={'me-2'}
                      style={{
                        color: 'var(--bs-danger)',
                      }}
                    />
                  )}
                </>
              ),
              startDate: task.startDate ? <DateTime date={task.startDate} hideTime={true} /> : null,
              dueDate: (
                <>
                  {/** && isPast(endOfWeek(parseISO(task.dueDate))) */}
                  {task.dueDate && task.scheduleMode !== TaskMode.Repeat && (
                    <strong className={`me-3 `}>
                      <DateTime date={task.dueDate} hideTime={true} />
                    </strong>
                  )}

                  {task.startDate && task.scheduleMode === TaskMode.Repeat && (
                    <strong className={`me-3 text-nowrap`}>
                      <DateTime date={task.startDate} hideTime={true} />
                      <IconRepeat className="ms-3" size="16px" />
                    </strong>
                  )}
                </>
              ),
              completed: (
                <>
                  {task.completed && task.completedUser && (
                    <Stack>
                      {task.completedUser.firstName} {task.completedUser.lastName}
                      {task.completedDate && (
                        <DateTime date={task.completedDate} hideTime={false} />
                      )}
                    </Stack>
                  )}
                </>
              ),
              assignedUser: (
                <>
                  {task.assignedUser && (
                    <>
                      {task.assignedUser.firstName} {task.assignedUser.lastName}
                    </>
                  )}
                </>
              ),
              actions: (
                <Dropdown as={ButtonGroup}>
                  {/*// not complete or complete*/}
                  {task.completed && (
                    <Button
                      busy={mutationCompleteTask.isLoading || tasksQuery.isLoading}
                      disabled={mutationCompleteTask.isLoading || tasksQuery.isLoading}
                      icon={IconNotComplete}
                      label={t('Not Complete')}
                      onClick={async (): Promise<Task | undefined> =>
                        await mutationPendingTask.mutateAsync(task.id)
                      }
                      size={'sm'}
                      variant={ButtonVariant.OutlinePrimary}
                    />
                  )}
                  {!task.completed && (
                    <Button
                      busy={mutationCompleteTask.isLoading || tasksQuery.isLoading}
                      disabled={mutationCompleteTask.isLoading || tasksQuery.isLoading}
                      icon={IconComplete}
                      label={t('Complete')}
                      onClick={async (): Promise<Task | undefined> =>
                        await mutationCompleteTask.mutateAsync(task.id)
                      }
                      size={'sm'}
                      variant={ButtonVariant.OutlinePrimary}
                    />
                  )}
                  <Dropdown.Toggle size="sm" split variant="outline-primary" />
                  <Dropdown.Menu align="end" flip>
                    {task.status !== TaskStatus.InProgress && (
                      <Dropdown.Item
                        onClick={async (): Promise<Task | undefined> =>
                          await mutationInProgressTask.mutateAsync(task.id)
                        }
                      >
                        <Icon
                          className={'me-1'}
                          path={mdiProgressCheck}
                          style={{ width: '18px' }}
                        />
                        {t('taskTable|statusInProgress')}
                      </Dropdown.Item>
                    )}
                    {task.status === TaskStatus.InProgress && (
                      <Dropdown.Item
                        onClick={async (): Promise<Task | undefined> =>
                          await mutationPendingTask.mutateAsync(task.id)
                        }
                      >
                        <IconNotComplete className={'me-1'} />

                        {t('taskTable|statusNotComplete')}
                      </Dropdown.Item>
                    )}
                    {!task.completed && (
                      <Dropdown.Item onClick={(): void => showCompleteWithCommentModal(task)}>
                        <IconCommentComplete className={'me-1'} />{' '}
                        {t('taskTable|statusCompleteWithComment')}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={(): void => showModal(task)}>
                      <IconEdit className={'me-1'} /> {t('Edit')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(): void => {
                        history.push(`/task/${task.id}`);
                      }}
                    >
                      <IconView className={'me-1'} /> {t('View')}
                    </Dropdown.Item>

                    <CopyToClipboard
                      text={`${process.env.REACT_APP_FRONTEND_URI}/task/${task?.id}`}
                    >
                      <Dropdown.Item>
                        <IconCopyToClipboard className={'me-1'} /> {t('Copy Link')}
                      </Dropdown.Item>
                    </CopyToClipboard>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={(): void => showDeleteModal(task)}>
                      <IconDelete className={'me-1'} /> {t('Delete')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ),
            } as DataTableRow;
          })
      : [];

  return (
    <>
      <DataTable
        breakpoint="sm"
        data={validData}
        headers={headers}
        isLoading={tasksQuery.isLoading}
        limit={parseInt(searchParam.get('limit') ?? '10')}
        offset={parseInt(searchParam.get('offset') ?? '0')}
        onLimitChange={(limit): void => {
          setSearchParam((prev) => {
            prev.set('limit', String(limit));
            return prev;
          });
        }}
        onOffsetChange={(offset): void => {
          setSearchParam((prev) => {
            prev.set('offset', String(offset));
            return prev;
          });
        }}
        onRowClick={(row): void => {
          const task = tasksQuery.data?.find((t) => t.id === Number(row.id));
          if (task) {
            showModal(task);
          }
        }}
        onSortDirectionChange={(direction): void => {
          setSortDirection(direction);
        }}
        onSortFieldChange={(field): void => {
          setSortField(field);
        }}
        sortDirection={sortDirection}
        sortField={sortField}
      />

      {taskModalVisible && (
        <TaskModal
          onClose={(): void => {
            setTaskModalVisible(false);
          }}
          task={taskModalTask}
        />
      )}
      {taskCompleteWithCommentModalVisible && (
        <TaskCommentModal
          onClose={(): void => setTaskCompleteWithCommentModalVisible(false)}
          taskId={taskModalTask?.Id ?? 0}
        />
      )}
      {taskDeleteModalVisible && (
        <ConfirmModal
          busy={deleteMutation.isLoading}
          cancelOnClick={(): void => setTaskDeleteModalVisible(false)}
          okIcon={IconDelete}
          okLabel={t('taskTable|deleteAlert|okLabel')}
          okOnClick={async (): Promise<void> => {
            await deleteMutation.mutateAsync();
            setTaskDeleteModalVisible(false);
          }}
          okVariant={ButtonVariant.Danger}
          title={t('taskTable|deleteAlert|title', { name: taskToDelete?.title })}
          visible={true}
        >
          <p>{t('taskTable|deleteAlert|prompt')}</p>
        </ConfirmModal>
      )}
    </>
  );
}
