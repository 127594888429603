// Copyright © 2024 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

export enum TaskMode {
  Once = 'once',
  Repeat = 'repeat',
}
