// Copyright © 2024 CATTLEytics Inc.

import { isNullOrWhitespace } from '../../shared/utilities';

export function toSafeUrl(input: string | undefined): string {
  if (isNullOrWhitespace(input)) {
    return '';
  }

  try {
    const validInput = input ?? '';
    const url = new URL(validInput);

    if (validInput.startsWith('http://') || validInput.startsWith('https://')) {
      return url.toString();
    } else {
      return url.toString();
    }
  } catch (e) {
    if (input && input.startsWith('https://')) {
      return '';
    }
    return toSafeUrl(`https://${input}`);
  }
}
