// Copyright © 2023 CATTLEytics Inc.

import MD5 from 'crypto-js/md5';
import { addDays, endOfDay, endOfWeek, isPast, parseISO, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useInjection } from 'inversify-react';
import React, { Fragment } from 'react';
import { Badge, ListGroup, ListGroupItem, Nav, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import DateTime from '../../common/components/DateTime';
import { TaskStatusIcon } from '../../common/components/TaskStatusIcon';
import { Sort, TaskPeriod } from '../../common/enums';
import TaskService from '../../common/services/taskService';
import { useSettingsContext } from '../../common/store/useSettingsContext';
import {
  extractAnimalReferencePrimaryTag,
  IconPriorityHighest,
  IconRepeat,
} from '../../common/utilities';
import { replaceMentionValues, Task, TaskPriority } from '../../shared';
import { QueryKey } from '../../shared/enums';
import { appendTaskGroup, isOverdue } from './utilities';

interface Props {
  /**
   * Filter list by animal ID.
   */
  animalId?: number;

  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  completedDateEnd?: Date;
  completedDateStart?: Date;
  dueDateEnd?: Date;
  dueDatePeriod?: TaskPeriod;
  dueDateStart?: Date;
  /**
   * Filter task list by category.
   */
  filterByCategoryId?: number;

  /**
   * Number of tasks to show.
   */
  limit?: number;

  /**
   * Show the name badge with different modes {@link NameMode} (initial, full, gravatar, firstplus)
   */
  nameMode?: NameMode;

  /**
   * Callback when list data is successfully retrieved
   * @param data
   */
  onQuerySuccess?: (data: Task[]) => void;

  onlyCompleted?: boolean;

  /**
   * Filter list by pen ID.
   */
  penId?: number;

  /**
   * Show completed
   */
  showCompleted?: boolean;

  /**
   * Direction to sort the list
   */
  sortDirection?: Sort;

  /**
   * Column to sort the list by
   */
  sortField?: string;

  /**
   * When set, truncates the text to a single line.
   */
  truncateText?: boolean;
}

type NameMode = 'initials' | 'full' | 'firstplus' | 'gravatar';

function TaskAssignedName(props: {
  email: string;
  firstName: string;
  lastName: string;
  mode: NameMode;
}): JSX.Element {
  if (props.mode === 'initials') {
    return (
      <Badge bg={'secondary'} pill>
        {props.firstName[0]} {props.lastName[0]}
      </Badge>
    );
  }
  if (props.mode === 'firstplus') {
    return (
      <Badge bg={'secondary'} pill>
        {props.firstName} {props.lastName[0]}
      </Badge>
    );
  }
  if (props.mode === 'gravatar') {
    // this is the same as the NavBar - could combine later.
    return (
      <>
        <Badge bg={'secondary'} pill>
          {props.firstName} {props.lastName[0]}
          <img
            alt={`${props.firstName} ${props.lastName}`}
            className={'rounded-circle rounded ms-2'}
            src={`https://www.gravatar.com/avatar/${MD5(props.email)}?d=wavatar`}
            style={{ height: '20px' }}
          />
        </Badge>
      </>
    );
  }
  return (
    <Badge bg={'secondary'} pill>
      {props.firstName} {props.lastName}
    </Badge>
  );
}

/**
 * List tasks.
 */
const TasksListWhiteboard = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const settings = useSettingsContext();

  const now = new Date();
  const todayStart = startOfDay(now);
  const todayEnd = endOfDay(now);
  const weekStart = addDays(startOfDay(now), 1);
  const weekEnd = addDays(endOfDay(weekStart), 7);

  const taskService = useInjection<TaskService>(TYPES.taskService);

  const queryParams: Record<string, string> = {
    sortField: props.sortField ? String(props.sortField) : 'dueDate',
    sortDirection: props.sortDirection ? String(props.sortDirection) : Sort.Ascending,
    limit: props.limit ? String(props.limit) : '10',
  };

  if (props.animalId) {
    queryParams.animalId = String(props.animalId);
  }

  if (props.filterByCategoryId) {
    queryParams.categoryId = String(props.filterByCategoryId);
  }

  if (props.dueDateStart) {
    queryParams.dueDateStart = props.dueDateStart.toISOString();
  }

  if (props.dueDateEnd) {
    queryParams.dueDateEnd = props.dueDateEnd.toISOString();
  }

  if (props.completedDateStart) {
    queryParams.completedDateStart = props.completedDateStart.toISOString();
  }

  if (props.completedDateEnd) {
    queryParams.completedDateEnd = props.completedDateEnd.toISOString();
  }

  if (props.onlyCompleted) {
    queryParams.onlyCompleted = '1';
  }

  if (props.dueDatePeriod) {
    switch (props.dueDatePeriod) {
      case TaskPeriod.Daily:
        queryParams.dueDateStart = todayStart.toISOString();
        queryParams.dueDateEnd = todayEnd.toISOString();
        break;
      case TaskPeriod.Weekly:
        queryParams.dueDateStart = weekStart.toISOString();
        queryParams.dueDateEnd = weekEnd.toISOString();
        break;
      case TaskPeriod.Whenever:
        queryParams.noDueDate = '1';
        break;
    }
  }

  queryParams.showCompleted = props.showCompleted ? '1' : '0';

  if (props.penId) {
    queryParams.penId = String(props.penId);
  }

  const query = useQuery<Task[]>(
    [QueryKey.Tasks, queryParams],
    () => taskService.list(queryParams),
    {
      onSuccess: props.onQuerySuccess,
      refetchInterval: 30_000,
      refetchOnWindowFocus: 'always',
      refetchOnMount: true,
      refetchOnReconnect: 'always',
      retry: true,
    },
  );

  const placeholder = (
    <Placeholder animation={'glow'} className={'w-100 d-flex justify-content-between'}>
      <div className={'w-100 d-flex'}>
        <Placeholder className={'me-3'} md={2} />
        <Placeholder md={4} />
      </div>
      <Placeholder className={'me-1'} md={1} />
    </Placeholder>
  );

  if (query.isLoading) {
    return (
      <Fragment>
        {[...Array(5).keys()].map((key) => (
          <Fragment key={key}>{placeholder}</Fragment>
        ))}
      </Fragment>
    );
  }

  if (query.isError || !query.data) {
    return (
      <AlertError
        message={`Error: Failed to get data at ${formatInTimeZone(
          query.errorUpdatedAt,
          settings.timeZone,
          `${settings.dateFormat} ${settings.timeFormat}`,
        )}`}
      />
    );
  }

  const tasks = query.data;

  return (
    <ListGroup className="overflow-md-auto flex-grow-md-1">
      {tasks.length === 0 && query.isFetched && (
        <p className={'text-center'}>
          <em>{t('No tasks found')}</em>
        </p>
      )}
      {tasks
        .map((task) => appendTaskGroup(task, settings.timeZone))
        .sort((a, b) => {
          // other ordering?
          return a.taskGroupOrder - b.taskGroupOrder;
        })
        .map((task) => {
          const related = [];

          if (task.animalId || task.penId) {
            if (task.animalId) {
              related.push({
                link: `/animals/${task.animalId}`,
                label: `Animal`,
                value: `#${extractAnimalReferencePrimaryTag(task.animal)}`,
              });
            }

            if (task.penId) {
              related.push({
                link: `/pens/${task.penId}`,
                label: `Pen`,
                value: `#${task.penId}`,
              });
            }
          }

          const animalPrimaryTag = extractAnimalReferencePrimaryTag(task.animal);
          const due = isOverdue(task, settings.timeZone);

          return (
            <ListGroupItem action={false} key={task.id}>
              <Nav.Link as={Link} disabled={false} eventKey={'tasks'} to={'/tasks/all'}>
                <div
                  className={'w-100 d-flex justify-content-between align-items-center gap-1'}
                  style={{ minWidth: 0 }}
                >
                  <div
                    className={`${props.truncateText ? 'text-truncate' : ''} ${
                      due ? 'text-danger' : 'text-muted'
                    } d-flex flex-row gap-2 align-content-center align-items-baseline `}
                    style={{ minWidth: 0 }}
                  >
                    <span>
                      <TaskStatusIcon overdue={due} status={task.status} />
                    </span>
                    {animalPrimaryTag && <small>#{animalPrimaryTag}</small>}

                    <div>
                      {(task.scheduleRepeat === undefined || task.scheduleRepeat === null) &&
                        task.dueDate &&
                        isPast(endOfWeek(parseISO(task.dueDate))) && (
                          <strong className="me-2">
                            <DateTime date={task.dueDate} hideTime={true} />
                          </strong>
                        )}

                      {task.scheduleRepeat && task.startDate && (
                        <>
                          <strong className="me-2">
                            <IconRepeat className="me-2" />
                            <DateTime date={task.startDate} hideTime={true} />
                          </strong>
                        </>
                      )}
                      <span>
                        {replaceMentionValues(
                          task.description.split('\n')[0],
                          (md) => `@${md.name}`,
                        )}
                      </span>
                    </div>
                  </div>

                  {!task.animalId && !task.penId && <div />}

                  <div className={'d-flex flex-column align-items-end gap-1'}>
                    {task.taskCategory && <Badge pill>{task.taskCategory.name}</Badge>}
                    {task.animalId && (
                      <Badge bg={'primary'} pill>
                        Animal
                      </Badge>
                    )}
                    {task.assignedUser && (
                      <TaskAssignedName
                        email={task.assignedUser.email}
                        firstName={task.assignedUser.firstName}
                        lastName={task.assignedUser.lastName}
                        mode={props.nameMode ?? 'full'}
                      />
                    )}
                    {task.priority === TaskPriority.Highest && (
                      <IconPriorityHighest
                        style={{
                          color: 'var(--bs-danger)',
                        }}
                      />
                    )}
                  </div>
                </div>
              </Nav.Link>
            </ListGroupItem>
          );
        })}
    </ListGroup>
  );
};

export default TasksListWhiteboard;
