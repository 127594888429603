// Copyright © 2023 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';

import { IconSave } from '../utilities';
import { ButtonVariant } from './Button';
import ButtonModal from './ButtonModal';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * Whether the button is in a busy state
   */
  busy?: boolean;
  className?: string;

  /**
   * Whether the button should be disabled.
   */
  disabled?: boolean;
}

/**
 * A button component for use in modals.
 */
const ButtonModalSave = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ButtonModal
      busy={props.busy}
      className={`w-100 w-auto-sm ${props.className}`}
      disabled={props.disabled}
      icon={IconSave}
      label={t('Save')}
      type="submit"
      variant={ButtonVariant.Primary}
    />
  );
};

export default ButtonModalSave;
