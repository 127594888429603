// Copyright © 2024 CATTLEytics Inc.

import { useState } from 'react';
import { Form } from 'react-bootstrap';

import Button from '../../common/components/Button';
import ImageUploaded from '../../common/components/ImageUploaded';
import ImageUploading from '../../common/components/ImageUploading';
import LightBoxModal from '../../common/components/LightBoxModal';
import NoImageUploaded from '../../common/components/NoImageUploaded';
import { getEnv, IconCreate, IconDelete } from '../../common/utilities';
import { ApiResourceV1, ImageUrl, isNullOrWhitespace } from '../../shared';

interface UploadFileFieldProps {
  addLabel?: string;
  busy: boolean;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  imageLabel?: string;
  imageUrls: ImageUrl[];
  onShow: () => void;
  removeImage: (index: number) => void;
  removeLabel?: string;
  show: boolean;
  uploadLabel?: string;
}

export function UploadFileField({
  imageUrls,
  removeImage,
  imageLabel = '',
  removeLabel,
  addLabel,
  handleFileUpload,
  uploadLabel,
  busy,
  show,
  onShow,
}: UploadFileFieldProps): JSX.Element {
  const [lightBoxModalVisible, setLightBoxModalVisible] = useState<boolean>(false);
  const [lightBoxImageUrl, setLightBoxImageUrl] = useState<string>('');

  return (
    <div className="mt-3">
      {show && (
        <div className={'mb-1'}>
          <Form.Control name={'imageUrl'} onChange={handleFileUpload} type="file" />
          <Form.Text className="text-muted">{uploadLabel}</Form.Text>
        </div>
      )}
      <div className={'d-flex flex-wrap justify-content-start gap-2'}>
        {imageUrls.map((imageUrl, index) => (
          <div key={index}>
            {/* When the image has only the key, grab the new signedUrls  */}
            {isNullOrWhitespace(imageUrls[index].signedUrl) &&
              !isNullOrWhitespace(imageUrls[index].key) && (
                <ImageUploaded
                  alt={imageLabel}
                  onClick={(): void => {
                    setLightBoxModalVisible(true);
                    setLightBoxImageUrl(
                      `${getEnv('API_ENDPOINT2')}${ApiResourceV1.Files}/${
                        imageUrls[index].key ?? ''
                      }?download=1`,
                    );
                  }}
                  src={`${getEnv('API_ENDPOINT2')}${ApiResourceV1.Files}/${
                    imageUrls[index].key ?? ''
                  }?download=1`}
                  style={{ cursor: 'pointer' }}
                />
              )}
            {imageUrl.signedUrl && (
              <div className={'text-center'}>
                {!busy && !imageUrls[index].signedUrl && <NoImageUploaded />}
                {imageUrls[index].signedUrl && (
                  <ImageUploaded
                    alt={imageLabel}
                    onClick={(): void => {
                      if (imageUrl.signedUrl) {
                        setLightBoxModalVisible(true);
                        setLightBoxImageUrl(imageUrl.signedUrl);
                      }
                    }}
                    src={imageUrls[index].signedUrl ?? ''}
                    style={{ cursor: 'pointer' }}
                  />
                )}

                <Button
                  className={'mt-1'}
                  icon={IconDelete}
                  onClick={(): void => {
                    removeImage(index);
                  }}
                  size={'sm'}
                  type={'button'}
                  variant={'outline-secondary'}
                >
                  {removeLabel}
                </Button>
              </div>
            )}
          </div>
        ))}
        {!show && (
          <Button
            className="rounded"
            icon={IconCreate}
            onClick={(): void => onShow()}
            size={'sm'}
            style={{ width: '150px', height: '150px' }}
            variant={'outline-secondary'}
          >
            {addLabel}
          </Button>
        )}
        {busy && <ImageUploading />}
        {lightBoxModalVisible && (
          <LightBoxModal
            imageUrl={lightBoxImageUrl}
            onClose={(): void => setLightBoxModalVisible(false)}
          />
        )}
      </div>
    </div>
  );
}
