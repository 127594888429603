// Copyright © 2023 CATTLEytics Inc.

import { useCallback } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import DatePicker, { DatePickerProps } from './DatePicker';

type Props<CustomModifierNames extends string = never> = Omit<
  DatePickerProps<CustomModifierNames, false>,
  'selected' | 'onChange' | 'selectsRange'
> & {
  labels?: {
    end: string;
    show: boolean;
    start: string;
  };
  onRangeChange: (start: Date | null, end: Date | null, event: any) => void;
};

export const DateRangePicker2 = <CustomModifierNames extends string = never>({
  labels,
  startDate,
  endDate,
  onRangeChange,
  minDate,
  ...props
}: Props<CustomModifierNames>): JSX.Element => {
  const { t } = useTranslation();

  const onStartChange = useCallback(
    (date, event) => {
      onRangeChange(date, endDate ?? null, event);
      // then focus on end date input
      setTimeout(() => {
        const elm = document.getElementById('end-date-range');
        elm?.focus();
      }, 100);
    },
    [onRangeChange, endDate],
  );

  return (
    <Stack className="flex-wrap" direction="horizontal" gap={2}>
      {labels?.show && (
        <div>
          <label htmlFor="start-date-range">{labels?.start}</label>
        </div>
      )}
      <div style={{ width: 145 }}>
        <DatePicker
          className="text-center"
          endDate={endDate}
          id="start-date-range"
          minDate={minDate}
          onChange={onStartChange}
          placeholderText={t('Start Date')}
          selected={startDate}
          selectsRange={false}
          selectsStart
          showMonthDropdown
          showYearDropdown
          startDate={startDate}
          useShortMonthInDropdown
          {...props}
        />
      </div>
      {labels?.show && (
        <div>
          <label htmlFor="end-date-range">{labels?.end}</label>
        </div>
      )}
      <div style={{ width: 145 }}>
        <DatePicker
          className="text-center"
          endDate={endDate}
          id="end-date-range"
          minDate={startDate}
          onChange={(date, event): void => onRangeChange(startDate ?? null, date, event)}
          placeholderText={t('End Date')}
          selected={endDate}
          selectsEnd
          selectsRange={false}
          showMonthDropdown
          showYearDropdown
          startDate={startDate}
          useShortMonthInDropdown
          {...props}
        />
      </div>
    </Stack>
  );
};
