// Copyright © 2024 CATTLEytics Inc.

import { IconLink } from '../../common/utilities';
import { WebUri } from '../../shared/entities/webUri';
import { isNullOrWhitespace } from '../../shared/utilities';
import { toSafeUrl } from './utilities.url';

export function UrlPreview(props: WebUri): JSX.Element {
  return (
    <div>
      {!isNullOrWhitespace(props.uri) ? (
        <a href={toSafeUrl(props.uri)} rel="noreferrer" target="_blank">
          <IconLink className="me-1" />
          {toSafeUrl(props.uri)}
        </a>
      ) : undefined}
    </div>
  );
}
