// Copyright © 2024 CATTLEytics Inc.

import { FormikErrors } from 'formik';
import { ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';
import { WeekdayInput } from '../../common/components/WeekdayInput';
import { Task } from '../../shared';
import { TaskMode } from '../../shared/enums/taskMode';
import { TaskRepeat } from '../../shared/enums/taskRepeat';
import { convertDaysFromIndex, convertDaysFromString } from './utilities';

export interface TaskScheduleResult {
  dayOfPeriod?: number;
  dayOfWeek?: string;
  interval?: number;
  intervalPeriod?: TaskRepeat;
  reset?: boolean;
}

export interface TaskScheduleProps {
  disabled?: boolean;

  errors: FormikErrors<Partial<Task>>;

  mode: TaskMode;

  /**
   * Callback is fired when any of the values are updated.
   */
  onChanged: (result: TaskScheduleResult) => void;

  /**
   * Callback is fired when changing from once to repeat.
   */
  onModeChanged: (mode: TaskMode) => void;

  value?: TaskScheduleResult;
}

export function TaskSchedule(props: TaskScheduleProps): JSX.Element {
  const { t } = useTranslation();

  const result = props.value ?? { interval: 1, intervalPeriod: TaskRepeat.Yearly, reset: false };

  return (
    <div>
      <Form.Group>
        <ButtonGroup>
          <Button
            disabled={props.disabled}
            onClick={(): void => {
              props.onModeChanged(TaskMode.Once);
            }}
            variant={props.mode === TaskMode.Once ? 'primary' : 'outline-primary'}
          >
            {t('taskSchedule|onceLabel')}
          </Button>
          <Button
            disabled={props.disabled}
            onClick={(): void => {
              props.onModeChanged(TaskMode.Repeat);
            }}
            variant={props.mode === TaskMode.Repeat ? 'primary' : 'outline-primary'}
          >
            {t('taskSchedule|repeatLabel')}
          </Button>
        </ButtonGroup>
      </Form.Group>
      {props.mode === TaskMode.Repeat && (
        <>
          <Form.Group className="mt-2">
            <InputGroup>
              <InputGroup.Text>{t('taskSchedule|everyLabel')}</InputGroup.Text>

              <Form.Control
                defaultValue={1}
                disabled={props.disabled}
                min={1}
                onChange={(ev): void => {
                  props.onChanged({ ...result, interval: parseInt(ev.target.value) });
                }}
                placeholder={`number`}
                type="number"
                value={result.interval}
              />
              <Form.Select
                disabled={props.disabled}
                onChange={(ev): void => {
                  props.onChanged({ ...result, intervalPeriod: ev.target.value as TaskRepeat });
                }}
                value={result.intervalPeriod}
              >
                <option value={TaskRepeat.Yearly}>{t('taskSchedule|yearLabel')}</option>
                <option value={TaskRepeat.Monthly}>{t('taskSchedule|monthLabel')}</option>
                <option value={TaskRepeat.Weekly}>{t('taskSchedule|weekLabel')}</option>
                <option value={TaskRepeat.Daily}>{t('taskSchedule|dayLabel')}</option>
              </Form.Select>
              <Form.Text>
                {props.errors.interval}
                {props.errors.intervalPeriod}
                {props.errors.dayOfPeriod}
              </Form.Text>
            </InputGroup>

            {props.value?.intervalPeriod === TaskRepeat.Monthly && (
              <div className="mt-2">
                <InputGroup>
                  <InputGroup.Text>On the</InputGroup.Text>

                  <Form.Control
                    defaultValue={1}
                    disabled={props.disabled}
                    min={1}
                    onChange={(ev): void => {
                      props.onChanged({ ...result, dayOfPeriod: parseInt(ev.target.value) });
                    }}
                    placeholder={`number`}
                    type="number"
                    value={result.dayOfPeriod}
                  />
                  <InputGroup.Text>Day</InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type={'invalid'}>
                  {props.errors.dayOfPeriod}
                </Form.Control.Feedback>
              </div>
            )}
            {props.value?.intervalPeriod === TaskRepeat.Weekly && (
              <div className="mt-2">
                <WeekdayInput
                  days={convertDaysFromString(props.value?.dayOfWeek ?? '')}
                  disabled={props.disabled}
                  onChange={(v): void => {
                    props.onChanged({ ...result, dayOfWeek: convertDaysFromIndex(v) });
                  }}
                  title="Occurs On"
                />
              </div>
            )}
          </Form.Group>
          <Form.Group className="mt-2">
            <Tooltip tooltip={<>{t('taskSchedule|resetTooltipLabel')}</>}>
              <Form.Check
                checked={result.reset}
                disabled={props.disabled}
                id="resetOnComplete"
                label={t('taskSchedule|resetOnCompleteLabel')}
                name="resetOnComplete"
                onChange={(ev): void => {
                  props.onChanged({ ...result, reset: ev.target.checked });
                }}
              />
            </Tooltip>
          </Form.Group>
        </>
      )}
    </div>
  );
}
