// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useTranslation, withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { TYPES } from '../types';
import AdminPage from './admin/AdminPage';
import SyncPage from './admin/SyncPage';
import { AnimalGroupsManagePage } from './animal-groups/AnimalGroupsManagePage';
import { AnimalGroupsPage } from './animal-groups/AnimalGroupsPage';
import AnimalCard from './animals/components/AnimalCard';
import AnimalsPage from './animals/components/AnimalsPage';
import PenCard from './animals/components/PenCard';
import PensPage from './animals/components/PensPage';
import SireCard from './animals/components/SireCard';
import SiresPage from './animals/components/SiresPage';
import AuthPage from './auth/components/AuthPage';
import PasswordResetPage from './auth/components/PasswordResetPage';
import SiteSwitcherModal from './auth/components/SiteSwitcherModal';
import BreedingPlanManage from './breeding-plans/components/BreedingPlanManage';
import BreedingPlans from './breeding-plans/components/BreedingPlans';
import AlertError from './common/components/AlertError';
import Footer from './common/components/Footer';
import NewButtonModal from './common/components/NewButtonModal';
import StylesPage from './common/components/StylesPage';
import Auth from './common/entities/auth';
import Session, { authDefault } from './common/entities/session';
import Setting, { SettingsAnimals, SettingsLocalization } from './common/entities/setting';
import AuthService from './common/services/authService';
import SettingService from './common/services/settingService';
import UserService from './common/services/userService';
import AuthContext from './common/store/auth-context';
import SettingsContext, { SettingsAnimalsContext } from './common/store/settings-context';
import { isExtraLarge } from './common/utilities';
import DairyLogsPage from './dairy-logs/components/DairyLogsPage';
import Dashboard from './dashboard/components/Dashboard';
import DiagnosisRegimeManagePage from './diagnoses/components/DiagnosisRegimeManagePage';
import DiagnosisRegimesPage from './diagnoses/components/DiagnosisRegimesPage';
import ActiveTreatmentsDetailPage from './events/components/ActiveTreatmentsDetailPage';
import ActiveTreatmentsPage from './events/components/ActiveTreatmentsPage';
import EventsPage from './events/components/EventsPage';
import EventsSummaryByMonthPage from './events/components/EventsSummaryByMonthPage';
import HealthChecksPage from './health-checks/components/HealthChecksPage';
import HelpPage from './help/components/HelpPage';
import Import from './import/components/Import';
import LactationManagementPage from './lactation-management/components/LactationManagementPage';
import ListsAnimalColorsPage from './lists/components/ListsAnimalColorsPage';
import ListsAnimalEventTypesPage from './lists/components/ListsAnimalEventTypesPage';
import ListsAnimalReferenceTypePage from './lists/components/ListsAnimalReferenceTypesPage';
import ListsBirthStatusesPage from './lists/components/ListsBirthStatusesPage';
import ListsBreedsPage from './lists/components/ListsBreedsPage';
import ListsDiagnosesPage from './lists/components/ListsDiagnosesPage';
import ListsGendersPage from './lists/components/ListsGendersPage';
import ListsPage from './lists/components/ListsPage';
import ListsProductClassesPage from './lists/components/ListsProductClassesPage';
import ListsProductTypesPage from './lists/components/ListsProductTypesPage';
import ListsReproStatusesPage from './lists/components/ListsReproStatusesPage';
import ListsTerminationCodesPage from './lists/components/ListsTerminationCodesPage';
import MastitisPage from './mastitis/components/MastitisPage';
import MilkQualityPage from './milk-quality/components/MilkQualityPage';
import MilkQuantityPage from './milk-quantity/components/MilkQuantityPage';
import NotFoundPage from './misc/components/NotFoundPage';
import PrivacyPage from './misc/components/PrivacyPage';
import TermsPage from './misc/components/TermsPage';
import NavBar from './nav/components/NavBar';
import NavLeft from './nav/components/NavLeft';
import NotePage from './notes/components/NotePage';
import NotesPage from './notes/components/NotesPage';
import { SignUpPage } from './onboarding';
import MethanePage from './operations/components/MethanePage';
import ProductsDetailPage from './products/components/ProductsDetailPage';
import ProductsPage from './products/components/ProductsPage';
import ReportsCustomPage from './reports/components/ReportsCustomPage';
import ReproductionManagementPage from './reproduction-management/components/ReproductionManagementPage';
import { SchedulesPage } from './schedules/SchedulesPage';
import SettingsPage from './settings/components/SettingsPage';
import { ScheduleContext, ScheduleContextProps } from './settings/context/ScheduleContext';
import { useSettingsSchedule } from './settings/hooks/useSettingsSchedule';
import { QueryKey, Session as SessionEntity, User } from './shared';
import SitesPage from './sites/components/SitesPage';
import { TaskCategoriesPage } from './tasks/components/TaskCategoriesPage';
import { TaskPage } from './tasks/components/TaskPage';
import { TasksPage } from './tasks/components/TasksPage';
import { TaskTemplatePage } from './tasks/components/TaskTemplatePage';
import { TaskTemplateView } from './tasks/components/TaskTemplateView';
import TerminationCodeDetailPage from './termination-code/components/TerminationCodeDetailPage';
import TestDataPage from './test-data/components/TestDataPage';
import AppLinkModal from './users/components/AppLinkModal';
import UserEditProfileModal from './users/components/UserEditProfileModal';
import UserPasswordChangeModal from './users/components/UserPasswordChangeModal';
import UsersPage from './users/components/UsersPage';
import WhiteboardPage from './whiteboard/components/WhiteboardPage';

/**
 * Root application component.
 */
const App = (): JSX.Element => {
  // state
  const [navClosed, setNavClosed] = useState<boolean>(!isExtraLarge());
  const [session, setSession] = useState<Session>(authDefault());
  // @TODO this should be moved to session
  const [userId, setUserId] = useState<number>(0);
  const [newButtonModalVisible, setNewButtonModalVisible] = useState(false);
  const [userPasswordChangeModalVisible, setUserPasswordChangeModalVisible] = useState(false);
  const [userEditProfileModalVisible, setUserEditProfileModalVisible] = useState(false);
  const [siteSwitchModalVisible, setSiteSwitchModalVisible] = useState(false);
  const [appLinkModalVisible, setAppLinkModalVisible] = useState(false);

  const [_, i18n] = useTranslation();

  // injections
  const authService = useInjection<AuthService>(TYPES.authService);
  const settingService = useInjection<SettingService>(TYPES.settingService);
  const userService = useInjection<UserService>(TYPES.userService);

  const querySettings = useQuery<Setting | undefined>(
    [QueryKey.Settings, 'localization'],
    () => settingService.get('localization'),
    {
      enabled: session.authenticated,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      retry: true,
    },
  );

  const queryAnimalSettings = useQuery<Setting | undefined>(
    [QueryKey.Settings, 'animals'],
    () => settingService.get('animals'),
    {
      enabled: session.authenticated,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      retry: true,
    },
  );

  const queryUser = useQuery<User | undefined>(
    [QueryKey.Users, userId],
    () => userService.get(userId),
    {
      enabled: session.authenticated,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      retry: true,
    },
  );

  useEffect(() => {
    if (queryUser.data) {
      if (queryUser.data.language) {
        i18n.changeLanguage(queryUser.data.language).then();
      }

      if (queryUser.data.theme) {
        document.documentElement.dataset.bsTheme = queryUser.data.theme;
      }

      // set the default app theme based on system preference
      // const darkMode =
      //   window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      // document.documentElement.dataset.bsTheme = darkMode ? 'dark' : 'light';
    }
  }, [queryUser.data, i18n]);

  // send page changes to Google Analytics
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  // choose the screen size
  const handleResize = (): void => {
    if (!isExtraLarge()) {
      setNavClosed(true);
    } else {
      setNavClosed(false);
    }
  };

  // set up the resize handler
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  // When the screen is smaller, add a click event to hide the nav to the window.
  // remove when in xl mode
  useEffect(() => {
    if (!isExtraLarge()) {
      window.addEventListener('click', hideNav);
    } else {
      window.removeEventListener('click', hideNav);
    }
    return (): void => window.removeEventListener('click', hideNav);
  }, [navClosed]);

  const hideNav = (): void => {
    setNavClosed(true);
  };

  const toggleNav = (e: React.MouseEvent<HTMLButtonElement>): void => {
    // without this the newly added click event will fire
    e.stopPropagation();
    if (!isExtraLarge()) {
      setNavClosed((prevNavClosed) => {
        return !prevNavClosed;
      });
    } else {
      setNavClosed((prevNavClosed) => !prevNavClosed);
    }
  };

  // run the resize handler on render
  useEffect(() => {
    handleResize();
  }, []);

  const query = useQuery<Session | undefined>([QueryKey.Auth], () => authService.get(1), {
    enabled: true,
    onSuccess: (data) => {
      setSession({ ...authDefault(), ...{ authenticated: (data as Session).authenticated } });
      if (data) {
        setUserId((data as SessionEntity).userId);
      }
    },
  });

  const {
    scheduleAllowSwapping: featureFlagAllowSwapping,
    scheduleRequestApproval: featureFlagRequestApproval,
  } = useSettingsSchedule();

  const scheduleContextValue: ScheduleContextProps = {
    featureFlags: {
      allowSwapping: featureFlagAllowSwapping,
      requestApproval: featureFlagRequestApproval,
    },
  };

  if (querySettings.isError) {
    return <AlertError message={'Could not load settings'} />;
  }

  if (query.isLoading || querySettings.isLoading) {
    return (
      <div
        className={'d-flex justify-content-center align-items-center'}
        style={{ minHeight: '100vh' }}
      >
        <Spinner animation={'border'} variant={'primary'} />
      </div>
    );
  }

  if (!session.authenticated) {
    return (
      <>
        <Switch>
          <Route component={PasswordResetPage} key="reset-pass" path="/reset-password/:token" />
          <Route key="onboarding" path="/sign-up/:stepName?">
            <SignUpPage />
          </Route>
          <Route key="auth" path="/">
            <AuthPage setSession={setSession} />
          </Route>
        </Switch>
      </>
    );
  }

  return (
    <AuthContext.Provider value={query.data as Auth}>
      <SettingsContext.Provider value={querySettings.data as SettingsLocalization}>
        <ScheduleContext.Provider value={scheduleContextValue}>
          <SettingsAnimalsContext.Provider value={queryAnimalSettings.data as SettingsAnimals}>
            <nav className={`nav-drawer ${navClosed ? 'is-closed' : ''}`}>
              {!navClosed && (
                <NavLeft
                  onAppLinkModalVisible={(): void => setAppLinkModalVisible(true)}
                  onChangePassword={(): void => setUserPasswordChangeModalVisible(true)}
                  onEditProfile={(): void => setUserEditProfileModalVisible(true)}
                  onNewButtonClick={(visible): void => setNewButtonModalVisible(visible)}
                  onSiteSwitch={(): void => setSiteSwitchModalVisible(true)}
                  setSession={setSession}
                />
              )}
            </nav>
            <main>
              <NavBar
                onNavToggle={toggleNav}
                onSignOut={(): void =>
                  setSession({ ...authDefault(), ...{ authenticated: false } })
                }
              />
              <div
                className={`nav-corner ${navClosed ? 'nav-corner-hidden' : ''}`}
                style={{
                  height: '50px',
                  width: '50px',
                  position: 'fixed',
                  top: 52,
                  zIndex: 10,
                  backgroundImage:
                    'radial-gradient(circle at 39px 39px, rgba(0, 0, 0, 0) 39px, rgba(0, 0, 0, 0) 40px, var(--bs-primary) 41px',
                }}
              />
              <Container className={'mt-3 min-vh-90'} fluid={true}>
                <Row>
                  <Col md={12}>
                    <Switch>
                      <Route
                        component={AdminPage}
                        exact
                        key="admin"
                        path="/system/:tabKey(global|this-site|all-sites)?"
                      />
                      <Route
                        component={SyncPage}
                        exact
                        key="admin-sync"
                        path="/system/sync/:tabKey?"
                      />
                      <Route
                        component={AnimalCard}
                        exact
                        key="animal"
                        path="/animals/:animalId([0-9]+)/:tabKey?"
                      />
                      <Route
                        component={AnimalGroupsPage}
                        exact
                        key="animal-groups"
                        path="/animals/groups"
                      />
                      <Route
                        component={AnimalGroupsManagePage}
                        exact
                        key="animal-groups-manage"
                        path="/animals/groups/:id/manage"
                      />
                      <Route
                        component={AnimalsPage}
                        exact
                        key="animals"
                        path="/animals/:tabKey(active|dead|sold|cows|heifers|calves)"
                      />
                      <Route
                        component={BreedingPlans}
                        exact
                        key="breedingPlans"
                        path="/operations/breeding-plans"
                      />
                      <Route
                        component={BreedingPlanManage}
                        exact
                        key="breedingPlansId"
                        path="/operations/breeding-plans/:id"
                      />
                      <Route
                        component={SchedulesPage}
                        exact
                        key="schedules"
                        path="/operations/schedules"
                      />
                      <Route component={DairyLogsPage} exact key="dairy-logs" path="/dairy-logs" />
                      <Route component={Dashboard} exact key="dashboard" path="/dashboard" />
                      <Route
                        component={DiagnosisRegimesPage}
                        exact
                        key="diagnosis-regimes"
                        path="/diagnosis-regimes"
                      />
                      <Route
                        component={DiagnosisRegimeManagePage}
                        exact
                        key="diagnosis-regimes-manage"
                        path="/diagnosis-regimes/:diagnosisRegimeId"
                      />
                      <Route
                        component={MethanePage}
                        exact
                        key="methane"
                        path="/operations/methane"
                      />
                      <Route
                        component={ReportsCustomPage}
                        exact
                        key="reports-custom"
                        path="/reports/custom/:queryEncoded"
                      />
                      <Route
                        component={EventsPage}
                        exact
                        key="events"
                        path="/events/:tabKey/:year?"
                      />
                      <Route
                        component={EventsSummaryByMonthPage}
                        exact
                        key="events-summary-by-month"
                        path="/events-summary-by-month"
                      />
                      <Route
                        component={ActiveTreatmentsPage}
                        exact
                        key="active-treatments"
                        path="/active-treatments/:tabKey(all|pending|completed)"
                      />
                      <Route
                        component={ActiveTreatmentsDetailPage}
                        exact
                        key="active-treatments-detail"
                        path="/active-treatments/:activeTreatmentId"
                      />
                      <Route
                        component={HealthChecksPage}
                        exact
                        key="health-checks-page"
                        path="/animal-health-checks"
                      />
                      <Route component={TestDataPage} exact key="test-data" path="/test-data" />
                      <Route component={HelpPage} exact key="help" path="/help" />
                      <Route exact key="home" path="/">
                        <Redirect to={'/dashboard'} />
                      </Route>
                      <Route component={ListsPage} exact key="lists" path="/lists" />
                      <Route
                        component={ListsAnimalColorsPage}
                        exact
                        key="lists-animal-colors"
                        path="/lists/animal-colors/:action?"
                      />
                      <Route
                        component={ListsBirthStatusesPage}
                        exact
                        key="lists-birth-statuses"
                        path="/lists/birth-statuses/:action?"
                      />
                      <Route
                        component={ListsDiagnosesPage}
                        exact
                        key="lists-diagnoses"
                        path="/lists/diagnoses/:action?"
                      />
                      <Route
                        component={ListsAnimalEventTypesPage}
                        exact
                        key="lists-animal-event-types"
                        path="/lists/animal-event-types/:action?"
                      />
                      <Route
                        component={ListsAnimalReferenceTypePage}
                        exact
                        key="lists-animal-reference-types"
                        path="/lists/animal-reference-types/:action?"
                      />
                      <Route
                        component={ListsBreedsPage}
                        exact
                        key="lists-breeds"
                        path="/lists/breeds/:action?"
                      />
                      <Route
                        component={ListsGendersPage}
                        exact
                        key="lists-genders"
                        path="/lists/genders/:action?"
                      />
                      <Route
                        component={ListsProductClassesPage}
                        exact
                        key="product-classes"
                        path="/lists/product-classes/:action?"
                      />
                      <Route
                        component={ListsProductTypesPage}
                        exact
                        key="product-types"
                        path="/lists/product-types/:action?"
                      />
                      <Route
                        component={ListsReproStatusesPage}
                        exact
                        key="lists-repro-statuses"
                        path="/lists/repro-statuses/:action?"
                      />
                      <Route
                        component={TaskCategoriesPage}
                        exact
                        key="task-categories"
                        path="/lists/task-categories/:tabKey/:action?"
                      />
                      <Route
                        component={ListsTerminationCodesPage}
                        exact
                        key="termination-codes"
                        path="/lists/termination-codes/:tabKey/:action?"
                      />
                      <Route
                        component={TerminationCodeDetailPage}
                        exact
                        key="termination-codes-view"
                        path="/termination-codes/:terminationCodeId"
                      />
                      <Route component={Import} exact key="import" path="/import" />
                      <Route component={PensPage} exact key="pens" path="/pens" />
                      <Route component={PenCard} exact key="pen-card" path="/pens/:penId" />
                      <Route
                        component={ProductsPage}
                        exact
                        key="products"
                        path="/lists/products/:tabKey(active|hidden)"
                      />
                      <Route
                        component={(): JSX.Element => <Redirect to="/lists/products/active" />}
                        exact
                        path="/lists/products"
                      />
                      <Route
                        component={ProductsDetailPage}
                        exact
                        key="products-view"
                        path="/products/:productId"
                      />
                      <Route component={PrivacyPage} exact key="privacy" path="/privacy" />
                      <Route component={NotePage} exact key="note" path="/notes/:noteId" />
                      <Route component={NotesPage} exact key="notes" path="/notes" />
                      <Route component={SiresPage} exact key="sires" path="/sires" />
                      <Route component={SireCard} exact key="sire-card" path="/sires/:sireId" />
                      <Route
                        component={SettingsPage}
                        exact
                        key="settings"
                        path="/settings/:tabKey"
                      />
                      <Route component={StylesPage} exact key="styles" path="/styles" />
                      <Route component={TaskTemplatePage} exact key="protocols" path="/protocols" />
                      <Route
                        component={TaskTemplateView}
                        exact
                        key="protocol"
                        path="/protocols/:protocolId"
                      />
                      <Route component={TasksPage} exact key="tasks" path="/tasks/:tabKey" />
                      <Route component={TaskPage} exact key="task" path="/task/:taskId" />
                      <Route component={TermsPage} exact key="terms" path="/terms" />
                      <Route
                        exact
                        key="sign-up-when-authenticated"
                        path="/sign-up"
                        render={(): ReactNode => <Redirect to={'/dashboard'} />}
                      />
                      <Route component={SitesPage} exact key="sites" path="/sites" />
                      <Route
                        component={UsersPage}
                        exact
                        key="users"
                        path="/users/:tabKey(all|all-sites|technicians|shifts)"
                      />
                      <Route component={WhiteboardPage} exact key="whiteboard" path="/whiteboard" />
                      <Route
                        component={MilkQualityPage}
                        exact
                        key="milk-quality"
                        path="/milk/milk-quality"
                      />
                      <Route
                        component={MilkQuantityPage}
                        exact
                        key="milk-quantity"
                        path="/milk/milk-quantity"
                      />
                      <Route
                        component={LactationManagementPage}
                        exact
                        key="lactation-management"
                        path="/milk/lactation-management"
                      />
                      <Route component={MastitisPage} exact key="mastitis" path="/milk/mastitis" />
                      <Route
                        component={ReproductionManagementPage}
                        exact
                        key="reproduction-management"
                        path="/reproduction/reproduction-management"
                      />
                      <Route component={NotFoundPage} key="not-found" />
                    </Switch>
                  </Col>
                </Row>
              </Container>
              <Footer />
              {/* Modals are outside the NavLeft because the drawer closes on mobile */}
              {newButtonModalVisible ? (
                <NewButtonModal onClose={(): void => setNewButtonModalVisible(false)} />
              ) : null}
              {userPasswordChangeModalVisible && (
                <UserPasswordChangeModal
                  onClose={(): void => setUserPasswordChangeModalVisible(false)}
                />
              )}

              {userEditProfileModalVisible && (
                <UserEditProfileModal onClose={(): void => setUserEditProfileModalVisible(false)} />
              )}
              {siteSwitchModalVisible && (
                <SiteSwitcherModal onClose={(): void => setSiteSwitchModalVisible(false)} />
              )}
              {appLinkModalVisible && (
                <AppLinkModal onClose={(): void => setAppLinkModalVisible(false)} />
              )}
            </main>
          </SettingsAnimalsContext.Provider>
        </ScheduleContext.Provider>
      </SettingsContext.Provider>
    </AuthContext.Provider>
  );
};

export default withTranslation()(App);
