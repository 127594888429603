// Copyright © 2024 CATTLEytics Inc.
import './TaskCategoriesManagePinned.scss';

import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import * as Icons from 'react-icons/io5';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, TaskCategory } from '../../shared';

interface SortableItemType {
  iconName?: string;
  id: string;
  name: string;
}

interface SortableItemProps {
  iconName?: string;
  id: string;
  name: string;
}
const SortableItem = (props: SortableItemProps): JSX.Element => {
  const { active, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });
  const isActive = active?.id === props.id;
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    aspectRatio: '1 / 1',
    //backgroundColor: isActive ? 'var(--bs-secondary)' : 'var(--bs-dark)',
    zIndex: isActive ? 1 : undefined,
    flexFlow: 'column wrap',
  };

  const Icon = props.iconName
    ? Icons[props.iconName as keyof typeof Icons]
    : (): JSX.Element => <div />;

  return (
    <div className={'rounded'}>
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div
          className={'rounded-circle d-flex justify-content-center align-items-center'}
          style={{
            width: '100px',
            height: '100px',
            backgroundColor: isActive ? 'var(--bs-secondary)' : 'var(--bs-primary)',
          }}
        >
          <Icon className={'text-light'} size={48} />
        </div>
        <div>{props.name}</div>
      </div>
    </div>
  );
};

export const TaskCategoriesManagePinned = (): JSX.Element => {
  const [taskCategories, setTaskCategories] = useState<SortableItemType[]>([]);

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }
    let sortedItems: SortableItemType[] = [];
    setTaskCategories((items) => {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      sortedItems = arrayMove(items, oldIndex, newIndex);
      return sortedItems;
    });
    mutation.mutateAsync(sortedItems).then();
  }

  const query = useQuery<TaskCategory[]>(QueryKey.TaskCategories, () =>
    api<TaskCategory[]>(HttpMethod.Get, ApiResourceV1.TaskCategories, {
      params: { limit: '100', sortField: 'order', sortDirection: 'asc' },
    }),
  );

  useEffect(() => {
    if (!query.data) {
      return;
    }

    setTaskCategories(
      query.data.map((category) => ({
        id: String(category.id),
        name: category.name,
        iconName: category.iconName,
      })),
    );
  }, [query.data]);

  const taskCategoryIds = useMemo(
    () => taskCategories.map((category) => String(category.id)),
    [taskCategories],
  );

  const queryClient = useQueryClient();

  const mutation = useMutation<undefined, undefined, SortableItemType[]>(
    (data) => {
      return api(HttpMethod.Post, ApiResourceV1.TaskCategoriesActionsReorder, {
        body: { taskCategoryIds: data.map((item) => Number(item.id)) },
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKey.TaskCategories),
    },
  );

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div
        className={'task-categories-manage-pinned'}
        style={{
          display: 'grid',
          gap: '0.5rem',
          gridTemplateColumns: '10rem 10rem 10rem',
          // gridTemplateColumns: '1fr 1fr 1fr',
          // width: 'minmax(10rem, 50rem)',
        }}
      >
        <SortableContext items={taskCategoryIds}>
          {taskCategories.map((item) => (
            <SortableItem iconName={item.iconName} id={item.id} key={item.id} name={item.name} />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );
};
