// Copyright © 2023 CATTLEytics Inc.

import { FC } from 'react';
import {
  Mention,
  MentionsInput,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions-continued';

import { Animal, ApiResourceV1, isNullOrWhitespace, nameTag } from '../../../shared';
import { api } from '../../utilities';

interface Props extends Omit<MentionsInputProps, 'children'> {
  isInvalid?: boolean;
  isValid?: boolean;
  required?: boolean;
}

/**
 * Gets the list of animals for the MentionsInput api.
 *
 * Based on example from https://github.com/signavio/react-mentions/blob/master/demo/src/examples/AsyncGithubUserMentions.js
 */
function fetchAnimals(query: string, callback: (data: SuggestionDataItem[]) => void): void {
  api<Animal[]>('GET', ApiResourceV1.Animals, {
    params: { search: query, includeReferenceAnimals: String(false), status: 'active' },
  })
    .then((res) =>
      res.map<SuggestionDataItem>(({ id, name, primaryTag }) => ({
        id,
        display: nameTag(primaryTag, name),
      })),
    )
    .then(callback);
}

export const AnimalMentionsTextArea: FC<Props> = (props) => {
  // The following input element is a way to trigger a javascript invalid
  // state so HTML5 form validation can be used since the input element
  // inside the typeahead component does not support this
  // if one or more items have been selected this input element will be
  // set to 1 thus satisfying the required validator
  const inputHelper = (
    <input
      aria-hidden={true}
      name={props.name}
      readOnly={true}
      required={props.required}
      style={{ display: 'none' }}
      tabIndex={-1}
      type={'text'}
      value={
        props.isInvalid || (props.isValid && props.required && isNullOrWhitespace(props.value))
          ? ''
          : '1'
      }
    />
  );

  return (
    <span className={`${props.isInvalid || !props.isValid ? 'is-invalid' : 'is-valid'}`}>
      {inputHelper}
      <MentionsInput {...props} className="mentions">
        <Mention
          data={fetchAnimals}
          displayTransform={(id, name): string => `@${name}`}
          trigger="@"
        />
      </MentionsInput>
    </span>
  );
};
