// Copyright © 2024 CATTLEytics Inc.

export const GoogleDriveIFrame = (props: { url: string }): JSX.Element => {
  if (
    props.url === undefined ||
    props.url === null ||
    !props.url.startsWith('https://drive.google.com/file/')
  ) {
    return <></>;
  }

  const uri = props.url.endsWith('/view') ? props.url.replace('/view', '/preview') : props.url;

  return <iframe allow="autoplay" height="480" src={uri} title="audio-player" width="640" />;
};
