// Copyright © 2023 CATTLEytics Inc.

import { formatInTimeZone } from 'date-fns-tz';
import React, { useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import ButtonEdit from '../../common/components/ButtonEdit';
import DateTime from '../../common/components/DateTime';
import Page from '../../common/components/Page';
import { useSettingsContext } from '../../common/store/useSettingsContext';
import { api } from '../../common/utilities';
import { ApiResourceV1, capitalizeWords, HttpMethod, QueryKey, Task } from '../../shared';
import { TaskMode } from '../../shared/enums/taskMode';
import { TaskRepeat } from '../../shared/enums/taskRepeat';
import { GoogleDriveIFrame } from './GoogleDriveIFrame';
import { ReadonlyField } from './ReadonlyField';
import { TaskCategoryBadge } from './TaskCategoryBadge';
import { TaskModal } from './TaskModal';
import { TaskPriorityLabel } from './TaskPriority';
import { TaskSchedule } from './TaskSchedule';
import { UrlPreview } from './UrlPreview';

interface RouteParams {
  /**
   * Tab key to make active on render.
   */
  taskId: string;
}

/**
 * @description Routable component to show a single task.
 */
export function TaskPage(): JSX.Element {
  const { t } = useTranslation();
  const { taskId } = useParams<RouteParams>();
  const settings = useSettingsContext();
  const [taskModalVisible, setTaskModalVisible] = useState<boolean>(false);
  const [taskModalTask, setTaskModalTask] = useState<Task>();

  const taskQuery = useQuery([QueryKey.Tasks, taskId], () => {
    return api<Task>(HttpMethod.Get, `${ApiResourceV1.Tasks}/${taskId}`);
  });

  const showModal = (task: Task): void => {
    setTaskModalVisible(true);
    setTaskModalTask(task);
  };

  if (taskQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Page
      breadcrumbTitle={capitalizeWords(taskId)}
      breadcrumbs={[
        { label: t('dairyLogsPage|title'), to: '/dairy-logs' },
        { label: t('Tasks'), to: '/tasks/all' },
      ]}
      buttons={
        <>
          <ButtonEdit
            onClick={(): void => {
              if (taskQuery.data) {
                showModal(taskQuery.data);
              }
            }}
          />
        </>
      }
      title={taskQuery.data?.description}
    >
      <Form>
        <Row>
          <Col md="6">
            <ReadonlyField label={t('Description')}>{taskQuery.data?.notes}</ReadonlyField>
            <ReadonlyField label={t('Instructions')}>{taskQuery.data?.instructions}</ReadonlyField>
            <ReadonlyField label={t('Audio')}>
              {taskQuery.data?.websiteAudioUrl ? (
                <>
                  <GoogleDriveIFrame url={taskQuery.data.websiteAudioUrl} />
                  <UrlPreview uri={taskQuery.data.websiteAudioUrl} />
                </>
              ) : (
                <UrlPreview uri={taskQuery.data?.websiteAudioUrl} />
              )}
            </ReadonlyField>
            <ReadonlyField label={t('Test')}>
              <UrlPreview uri={taskQuery.data?.websiteTestUrl} />
            </ReadonlyField>
            <ReadonlyField label={t('Website')}>
              <UrlPreview uri={taskQuery.data?.websiteUrl} />
            </ReadonlyField>
            <ReadonlyField label={t('Category')}>
              {taskQuery.data?.taskCategory && (
                <TaskCategoryBadge category={taskQuery.data?.taskCategory} />
              )}
            </ReadonlyField>
            <ReadonlyField label={t('Priority')}>
              {taskQuery.data?.priority && (
                <TaskPriorityLabel priority={taskQuery.data?.priority} />
              )}
            </ReadonlyField>
          </Col>
          <Col md="6">
            <ReadonlyField label={t('Assigned To')}>
              {taskQuery.data?.assignedUser
                ? `${taskQuery.data?.assignedUser?.firstName} ${taskQuery.data?.assignedUser?.lastName}`
                : ''}
            </ReadonlyField>
            {taskQuery.data?.completedUser && (
              <ReadonlyField label={t('Completed By')}>
                {taskQuery.data?.completedUser
                  ? `${taskQuery.data?.completedUser?.firstName} ${taskQuery.data?.completedUser?.lastName}`
                  : ``}
              </ReadonlyField>
            )}
            <ReadonlyField label={t('Status')}>
              {capitalizeWords(taskQuery.data?.status ?? '')}
            </ReadonlyField>
            <ReadonlyField label={t('Start Date')}>
              {taskQuery.data?.startDate
                ? formatInTimeZone(taskQuery.data?.startDate, settings.timeZone, 'yyyy-MM-dd')
                : ''}
            </ReadonlyField>

            {(taskQuery.data?.scheduleMode === undefined ||
              taskQuery.data?.scheduleMode === TaskMode.Once) && (
              <ReadonlyField label={t('Due Date')}>
                {taskQuery.data?.dueDate
                  ? formatInTimeZone(taskQuery.data?.dueDate, settings.timeZone, 'yyyy-MM-dd')
                  : ''}
              </ReadonlyField>
            )}
            {taskQuery.data?.scheduleMode === TaskMode.Repeat && (
              <>
                <TaskSchedule
                  disabled={true}
                  errors={{}}
                  mode={TaskMode.Repeat}
                  onChanged={(): void => {
                    return;
                  }}
                  onModeChanged={(): void => {
                    return;
                  }}
                  value={{
                    interval: taskQuery.data?.scheduleRepeatInterval ?? 1,
                    intervalPeriod: taskQuery.data?.scheduleRepeat ?? TaskRepeat.Yearly,
                    dayOfWeek: taskQuery.data?.scheduleRepeatDayOfWeek ?? '',
                    dayOfPeriod: taskQuery.data?.scheduleRepeatDayOfPeriod,
                    reset: taskQuery.data?.scheduleReset ?? false,
                  }}
                />

                <ReadonlyField label={t('Next Iteration')}>
                  {taskQuery.data?.dueDate
                    ? formatInTimeZone(taskQuery.data?.dueDate, settings.timeZone, 'yyyy-MM-dd')
                    : ''}
                </ReadonlyField>
                <ReadonlyField label={t('End Date')}>
                  <DateTime date={taskQuery.data?.scheduleEndDate ?? ''} />
                </ReadonlyField>
              </>
            )}
          </Col>
        </Row>
      </Form>

      {taskModalVisible && (
        <TaskModal onClose={(): void => setTaskModalVisible(false)} task={taskModalTask} />
      )}
    </Page>
  );
}
