// Copyright © 2024 CATTLEytics Inc.

import React, { useMemo, useState } from 'react';
import { Badge, Col, Dropdown, Form, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';

import { DateRangePicker2 } from '../../common/components/DateRangePicker2';
import { Spinner } from '../../common/components/Spinner';
import { IconCheck } from '../../common/utilities';
import { QueryKey, TaskCategory, User } from '../../shared';
import { UserAutocomplete } from '../../users/components/UserAutocomplete';
import { TaskCategoryAutocomplete } from './TaskCategoryAutocomplete';
import { TaskTable, TaskTableFilters, TaskTableProps } from './TaskTable';

export interface TaskTableWithFiltersProps extends TaskTableProps {
  showCategoryFilters?: boolean;
  showFilters?: boolean;
}

export function FilteredTaskTable({
  showFilters = true,
  showCategoryFilters = true,
  ...props
}: TaskTableWithFiltersProps): JSX.Element {
  const [filters, setFilters] = useState<TaskTableFilters>(props.filters ?? {});

  return (
    <>
      {showFilters && (
        <TaskFilters filters={filters} onFilterChanged={(filters): void => setFilters(filters)}>
          {showCategoryFilters && (
            <TaskCategoryFilter
              filters={filters}
              onFilterChanged={(filters): void => setFilters(filters)}
            />
          )}
        </TaskFilters>
      )}

      <TaskTable {...props} filters={filters} />
    </>
  );
}

export function TaskCategoryFilter(props: {
  filters: TaskTableFilters;
  onFilterChanged: (filters: TaskTableFilters) => void;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Form.Group className="">
        <Form.Label column>{t('taskTable|categoryLabel')}</Form.Label>
        <Col>
          <TaskCategoryAutocomplete
            id={'taskCategoryFilter'}
            multiple
            onSelect={(category?: TaskCategory | TaskCategory[] | undefined): void => {
              props.onFilterChanged({
                ...props.filters,
                categories:
                  category === undefined ? [] : Array.isArray(category) ? category : [category],
              });
            }}
            selected={props.filters.categories}
          />
        </Col>
      </Form.Group>
    </>
  );
}

export interface TaskFiltersProps {
  filters: TaskTableFilters;
  onFilterChanged: (filters: TaskTableFilters) => void;
}

export function TaskFilters(props: React.PropsWithChildren<TaskFiltersProps>): JSX.Element {
  const { t } = useTranslation();
  const fetchingTasks = useIsFetching({ queryKey: [QueryKey.Tasks] });

  return (
    <Stack className="w-100 " direction="horizontal" gap={3}>
      <Form className="w-100 d-flex flex-column flex-lg-row gap-2 justify-content-start">
        <Form.Group className="flex-grow-1">
          <Form.Label column>{t('taskTable|searchLabel')}</Form.Label>
          <Col className="flex-grow-1">
            <Form.Control
              onChange={(ev): void =>
                props.onFilterChanged({ ...props.filters, search: ev.currentTarget.value })
              }
              placeholder={t('taskTable|searchPlaceholder')}
              type="text"
              value={props.filters.search}
            />
          </Col>
        </Form.Group>
        <Form.Group className="flex-grow-1">
          <Form.Label column>{t('taskTable|assignedToLabel')}</Form.Label>
          <Col className="flex-grow-1">
            <UserAutocomplete
              id={''}
              multiple
              onSelect={(user?: User | User[] | undefined): void => {
                props.onFilterChanged({
                  ...props.filters,
                  assignedTo: user === undefined ? [] : Array.isArray(user) ? user : [user],
                });
              }}
              selected={props.filters.assignedTo}
            />
          </Col>
        </Form.Group>

        {props.children}

        <Form.Group className="">
          <Form.Label column>{t('taskTable|dueDateLabel')}</Form.Label>
          <DateRangePicker2
            autoComplete="off"
            endDate={props.filters.dateRange?.endDate ?? undefined}
            onRangeChange={(start, end): void => {
              props.onFilterChanged({
                ...props.filters,
                dateRange: {
                  startDate: start,
                  endDate: end,
                },
              });
            }}
            required
            startDate={props.filters.dateRange?.startDate ?? undefined}
          />
        </Form.Group>

        <Form.Group className="align-content-end">
          <MoreFiltersMenu {...props} />
        </Form.Group>

        <Form.Group className="ms-auto align-middle" style={{ width: '48px' }}>
          {fetchingTasks > 0 && <Spinner />}
        </Form.Group>
      </Form>
    </Stack>
  );
}

function MoreFiltersMenu(props: TaskFiltersProps): JSX.Element {
  const { t } = useTranslation();

  const filterMoreCount = useMemo(() => {
    let filters = 0;
    filters += props.filters.includeUpcoming === true ? 1 : 0;
    filters += props.filters.completed === 'show' ? 1 : 0;
    filters += props.filters.completed === 'only' ? 1 : 0;

    return filters;
  }, [props.filters]);

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" variant="outline-primary">
        {t('taskTable|moreFiltersLabel')}{' '}
        {filterMoreCount > 0 ? <Badge>{filterMoreCount}</Badge> : undefined}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={(): void => {
            props.onFilterChanged({
              ...props.filters,
              includeUpcoming: !(props.filters?.includeUpcoming ?? false),
            });
          }}
        >
          {props.filters?.includeUpcoming === true && <IconCheck className="me-2" />}
          {t('taskTable|upcomingLabel')}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(): void => {
            props.onFilterChanged({
              ...props.filters,
              completed: props.filters?.completed === 'show' ? 'hide' : 'show',
            });
          }}
        >
          {props.filters?.completed === 'show' && <IconCheck className="me-2" />}
          {t('taskTable|completedShowLabel')}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(): void => {
            props.onFilterChanged({
              ...props.filters,
              completed: props.filters?.completed === 'only' ? 'hide' : 'only',
            });
          }}
        >
          {props.filters?.completed === 'only' && <IconCheck className="me-2" />}
          {t('taskTable|completedOnlyLabel')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
