// Copyright © 2024 CATTLEytics Inc.

import { Col, Form, Row } from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';

import TimeSelect from '../../common/components/TimeSelect';
import { isNullOrWhitespace } from '../../shared';

export interface FormControlDateProps {
  date: string;
  errors?: string;
  instruction: string;
  isInvalid?: boolean;
  isValid?: boolean;
  label: string;
  name?: string;

  setDate: (date: string) => void;
  setTime: (date: Option) => void;
  time: Option;
  validated?: boolean;
}

export function FormControlDate(props: FormControlDateProps): JSX.Element {
  const { setDate, setTime, date, time, validated } = props;

  return (
    <Form.Group className="form-group mb-3" controlId="formStartDate">
      <Form.Label>{props.label}</Form.Label>
      <Row>
        <Col md={date ? 6 : 12}>
          <Form.Control
            aria-label={props.label}
            isInvalid={props.isInvalid}
            isValid={props.isValid}
            name={props.name}
            onChange={(event): void => {
              setDate(event.target.value);
            }}
            type={'date'}
            value={date}
          />
        </Col>
        {!isNullOrWhitespace(date) && (
          <Col md={6}>
            <TimeSelect
              className="mt-2 mt-md-0 time-dropdown"
              id="startTime"
              onChange={(e): void => setTime(e[0])}
              required={true}
              validateEarly={true}
              validated={validated ?? false}
              value={time}
            />
          </Col>
        )}
      </Row>
      <Form.Text className={'text-muted'}>{props.instruction}</Form.Text>
      <Form.Control.Feedback type="invalid">{props.errors}</Form.Control.Feedback>
    </Form.Group>
  );
}
