// Copyright © 2023 CATTLEytics Inc.

import { differenceInMinutes, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { Badge, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../types';
import AlertError from '../common/components/AlertError';
import Button from '../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../common/components/DataTable';
import DateTime from '../common/components/DateTime';
import JsonModal from '../common/components/JsonModal';
import TablePlaceholder from '../common/components/TablePlaceholder';
import Toast from '../common/components/Toast';
import { Sort } from '../common/enums';
import JobService from '../common/services/jobService';
import { getEnv, IconCode, IconCopyToClipboard, IconDownload, IconWait } from '../common/utilities';
import { ApiResourceV1, QueryKey } from '../shared';

function Duration(row: { completedOn: any; id: string; startedOn: any }): JSX.Element {
  const { t } = useTranslation();
  const delta = differenceInMinutes(parseISO(row.completedOn), parseISO(row.startedOn));

  const duration =
    row.startedOn && row.completedOn
      ? formatDuration(
          intervalToDuration({
            start: parseISO(row.startedOn),
            end: parseISO(row.completedOn),
          }),
          {
            zero: false,
            format: ['hours', 'minutes', 'seconds', 'milliseconds'],
          },
        )
      : null;

  return (
    <OverlayTrigger
      overlay={
        <Tooltip className="tooltip-unbound" id={row.id + '-duration'}>
          {duration !== null ? duration || t('jobsTable|underOneSecondTooltip') : null}
        </Tooltip>
      }
    >
      <span>
        {row.startedOn && row.completedOn ? (
          <Stack direction="horizontal" gap={2}>
            <IconWait
              className="me-auto"
              color={delta > 30 ? 'red' : delta > 10 ? 'purple' : delta > 5 ? 'yellow' : 'green'}
            />
            {duration || t('jobsTable|underOneSecondTooltip')}
          </Stack>
        ) : (
          <small>{t('N/A')}</small>
        )}
      </span>
    </OverlayTrigger>
  );
}

interface Props {
  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Keys to filter data.
   */
  filters?: Record<string, string>;

  /**
   * Number of jobs to show.
   */
  initialLimit?: number;

  /**
   * Number of jobs to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * A table component containing job data.
 */
const JobsTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const jobService = useInjection<JobService>(TYPES.jobService);
  const queryClient = useQueryClient();

  const filter: undefined | Record<string, string> = props.filters;
  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'lastName');
  const [jobData, setJobData] = useState<Record<string, string> | undefined>();
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );
  const [showCopyToast, setShowCopyToast] = useState(false);

  const tab = useMemo(() => {
    if (!props.filters) {
      return '';
    }
    if (props.filters.siteId) {
      return 'this-site';
    }
    if (props.filters.hideSiteJobs) {
      return 'global';
    }
    if (props.filters.hideGlobalJobs) {
      return 'all-sites';
    }
    return '';
  }, [props.filters]);

  const query = useQuery(
    [QueryKey.Jobs, filter, limit, offset, sortField, sortDirection],
    () =>
      jobService.list({
        ...filter,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { enabled: true, keepPreviousData: true, refetchInterval: 15000 },
  );

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return (
      <AlertError
        message={t('common|unexpectedRetrievalError', {
          value: t('jobs'),
        })}
      />
    );
  }

  const headers: DataTableHeader[] = [
    {
      name: 'id',
      label: t('ID'),
      className: 'text-nowrap',
      colClassName: 'text-nowrap',
    },
    {
      name: 'name',
      label: t('Queue'),
    },
    {
      name: 'siteId',
      label: t('Site ID'),
      className: 'text-nowrap',
      colClassName: 'text-end',
    },
    {
      name: 'jobName',
      label: t('Job Name'),
    },
    {
      name: 'state',
      label: t('State'),
    },
    {
      name: 'duration',
      label: t('admin|jobsTable|duration'),
      colClassName: 'text-end',
    },
    {
      name: 'data',
      label: t('Data'),
    },
    {
      name: 'downloadUrl',
      label: t('jobsTable|download'),
      infoTooltip: t('jobsTable|downloadTooltip'),
    },
    {
      name: 'output',
      label: t('Output'),
    },
    {
      name: 'createdOn',
      label: t('Created'),
      colClassName: 'text-end',
    },
    {
      name: 'startedOn',
      label: t('Started'),
      colClassName: 'text-end',
    },
    {
      name: 'completedOn',
      label: t('Completed'),
      colClassName: 'text-end',
    },
    {
      name: 'cancel',
      label: t('jobsTable|actionsLabel'),
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => ({
        rawId: String(row.id),
        rawOutput: String(row.output),
        id: (
          <OverlayTrigger
            overlay={
              <Tooltip className="tooltip-unbound" id={row.id}>
                {row.id}
              </Tooltip>
            }
          >
            <div
              onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <CopyToClipboard onCopy={(): void => setShowCopyToast(true)} text={row.id || ''}>
                <small>
                  <IconCopyToClipboard />
                </small>
              </CopyToClipboard>
            </div>
          </OverlayTrigger>
        ),
        name: <small>{row.name}</small>,
        jobName: <small>{row.jobKey ?? ''}</small>,
        state: (
          <Badge
            bg={((): string => {
              switch (row.state) {
                case 'created':
                  return 'info';
                case 'completed':
                  return 'success';
                case 'failed':
                  return 'danger';
                case 'cancelled':
                  return 'secondary';
                case 'active':
                  return 'warning';
                case 'expired':
                  return 'danger';
                default:
                  return '';
              }
            })()}
          >
            {row.state}
          </Badge>
        ),
        siteId: row.siteId ? String(row.siteId) : <small>{t('N/A')}</small>,
        downloadUrl:
          tab === 'this-site' && row.data.file ? (
            <IconDownload
              className="cursor-pointer"
              onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
                window.location.href = `${getEnv('API_ENDPOINT2')}${
                  ApiResourceV1.Files
                }/${row.data.file?.replace(/[0-9]*\//, '')}?download=1`;
                return;
              }}
            />
          ) : (
            <small>{t('N/A')}</small>
          ),
        data: (
          <IconCode
            className="cursor-pointer"
            onClick={(e): void => {
              e.preventDefault();
              e.stopPropagation();
              setJobData(row.data);
            }}
          />
        ),
        startedOn: (
          <small>
            <DateTime date={row.startedOn} />
          </small>
        ),
        completedOn: (
          <small>
            <DateTime date={row.completedOn} />
          </small>
        ),
        createdOn: (
          <small>
            <DateTime date={row.createdOn} />
          </small>
        ),
        duration: <Duration completedOn={row.completedOn} id={row.id} startedOn={row.startedOn} />,
        output: row.output ? (
          <IconCode
            className="cursor-pointer"
            onClick={(e): void => {
              e.preventDefault();
              e.stopPropagation();
              setJobData(row.output);
            }}
          />
        ) : (
          <small>{t('N/A')}</small>
        ),
        cancel: (
          <Button
            disabled={row.state !== 'created'}
            onClick={(e): void => {
              e.preventDefault();
              e.stopPropagation();
              cancelJob(row.id);
            }}
            size={'sm'}
            title={t('jobTable|cancelButtonTitle')}
            variant={'danger'}
          >
            {t('Cancel')}
          </Button>
        ),
      }));

  const cancelJob = async (id: string): Promise<void> => {
    await jobService.delete(id);
    await queryClient.invalidateQueries(QueryKey.Jobs);
  };

  return (
    <div>
      <DataTable
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('jobsTable|noDataFoundMessage', {
          value: t('entity|job', { count: 1 }),
        })}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => {
          if (!query.data) return;
          const originalRow = query.data.find((job) => job.id === row.rawId);
          if (!originalRow) return;
          setJobData(originalRow.output);
        }}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {jobData && <JsonModal json={jobData} onClose={(): void => setJobData(undefined)} />}
      <Toast
        closeOnClick={true}
        delay={3000}
        onClose={(): void => setShowCopyToast(false)}
        show={showCopyToast}
      >
        {t('jobsTable|copiedToClipboardToast')}
      </Toast>
    </div>
  );
};

export default JobsTable;
