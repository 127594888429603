// Copyright © 2024 CATTLEytics Inc.

import { PropsWithChildren } from 'react';
import { Form, FormGroup } from 'react-bootstrap';

export function ReadonlyField(props: PropsWithChildren<{ label: string }>): JSX.Element {
  return (
    <FormGroup className="form-group mb-3">
      <Form.Label>{props.label}</Form.Label>
      <div style={{ whiteSpace: 'pre-line' }}>{props.children}</div>
    </FormGroup>
  );
}
