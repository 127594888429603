// Copyright © 2024 CATTLEytics Inc.

import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import { isNullOrWhitespace } from '../../shared';

const weekdays = [
  { day: 0, label: 'S' },
  { day: 1, label: 'M' },
  { day: 2, label: 'T' },
  { day: 3, label: 'W' },
  { day: 4, label: 'T' },
  { day: 5, label: 'F' },
  { day: 6, label: 'S' },
];

type Props = {
  days: number[];
  disabled?: boolean;
  id?: string;
  onChange: (value: number[]) => void;
  title?: string;
};

export function WeekdayInput({
  id = `Weekday`,
  days,
  disabled,
  onChange,
  title = '',
}: Props): JSX.Element {
  return (
    <ToggleButtonGroup className="mb-3" onChange={onChange} type="checkbox" value={days}>
      {!isNullOrWhitespace(title) && (
        <ToggleButton disabled id={''} value="" variant="outline-primary">
          {title}
        </ToggleButton>
      )}
      {weekdays.map(({ day, label }) => (
        <ToggleButton
          className={`px-3`}
          disabled={disabled}
          id={`${id}-${day}`}
          key={day}
          value={day}
          variant="outline-primary"
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
